import React, { useContext } from 'react';
import ParticlesComponent from './site/Components/particles.jsx';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from './app/Context/auth.jsx';

/* Paginas */
import Site from './site/site.jsx';
/* Links */
import Siganos from './contacte-nos/siga-nos.jsx';
import Descadastrese from './descadastrese/descadastrese.jsx';
import Descadastramento from './descadastrese/descadastramento.jsx';
import Certificacaodigital from './parceiros/certificacaodigital.jsx';
import Fisconet from './parceiros/Fisconet/fisconet.jsx';
import Avisoferiado from './link-avisos/aviso-feriado.jsx';
import GestaoNFE from './link-marketing/getao-de-estoque-emissao-nfe/gestao-de-estoque-emissao-nfe.jsx';
import LinkDownloadPlanilhaSimplesNacional from './link-baixar-arquivos/baixar-planilha-simples-naconal.jsx';
import LinkDownloadPlanilhasAuxiliares from './link-baixar-arquivos/baixar-planilha-planilhas-auxiliares.jsx';
import LinkDownloadDeclaracaoSNparaNaoSofrerRetencoes from './link-baixar-arquivos/baixar-declaracao-sn-para-nao-sofrer-retencoes.jsx';

function App() {
  const { logado } = useContext(AuthContext);

  function SecureRoute({ ...params }) {
    if (!logado) {
      return <Navigate to="/app" />;
    } else {
      return <Route {...params} />;
    }
  }

  return (
    <BrowserRouter>
    <ParticlesComponent id="particles" />
      <Routes>
        <Route path='/' element={<Site />} />
        <Route path='/siga-nos' element={<Siganos />} />
        <Route path='/descadastrese' element={<Descadastrese />} />
        <Route path='/descadastramento-realizado-com-sucesso' element={<Descadastramento />} />
        <Route path='/parceiro-fisconet-cursos-online/' element={<Fisconet />} />
        <Route path='/parceiro-certificacao-digital' element={<Certificacaodigital />} />
        <Route path='/aviso-de-feriado' element={<Avisoferiado />} />
        <Route path='/gestao-de-estoque-emissao-nfe' element={<GestaoNFE />} />
        <Route path='/download-planilha-simples-nacional' element={<LinkDownloadPlanilhaSimplesNacional />} />
        <Route path='/download-planilha-auxiliares' element={<LinkDownloadPlanilhasAuxiliares />} />
        <Route path='/download-declaracao-sn-para-nao-sofrer-retencoes' element={<LinkDownloadDeclaracaoSNparaNaoSofrerRetencoes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
